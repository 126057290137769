<template>
  <div>
    <el-page-header @back="goBack" content="文章详情页面"></el-page-header>
    <div class="new">
      <el-form label-width="80px">
        <el-form-item label="文章图">
          <img :src="img" alt style="width:150px" />
        </el-form-item>
        <el-form-item label="文章标题">
          <el-input v-model="title" class="text" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="文章内容">
            <!-- <editor-bar v-model="content" :isClear="isClear"></editor-bar> -->
          <el-input v-model="content" class="text" type="textarea" rows="5" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="文章作者">
          <el-input v-model="author" class="text" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="文章导语">
          <el-input v-model="intro" class="text" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="是否精选">
          <el-button v-if="isChoiceness == 1" :disabled="true" type="text" size="small">是</el-button>
          <el-button v-if="isChoiceness == 0" :disabled="true" type="text" size="small">否</el-button>
        </el-form-item>
        <el-form-item label="是否推荐">
          <el-button v-if="isRecommend == 1" :disabled="true" type="text" size="small">是</el-button>
          <el-button v-if="isRecommend == 0" :disabled="true" type="text" size="small">否</el-button>
        </el-form-item>
        <el-form-item label="引用">
          <el-input v-model="reference" class="text" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="创作时间">
          <el-input v-model="createTime" class="text" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import EditorBar from "@/components/wangEnduit/index";
import { showTimeFormat } from "@/js/common/index.js";
//加载数据库
import { DELETE, QUERYED } from "@/services/dao.js";
export default {
  data() {
    return {
      id: 0,
      img: "",
      title: "",
      author: "",
      content: "",
      isChoiceness: 0,
      isRecommend: 0,
      reference: "",
      intro: "",
      createTime: "",
      isClear: false
    };
  },
  components: { EditorBar },
  created() {
    this.id = this.$route.query.id;
    this.init();
  },
  methods: {
    //首次加载显示
    async init() {
      let data = await QUERYED(
        "post",
        "",
        " TtArticle(where: {id:  {_eq: " +
          this.id +
          "}}) { id img intro title author coverImg content isRecommend reference createTime isChoiceness }"
      );
      console.log(data);
      (this.id = data.data.TtArticle[0].id),
        (this.img =  this.api.LoginURL.concat(data.data.TtArticle[0].coverImg)),
        (this.title = data.data.TtArticle[0].title),
        (this.author = data.data.TtArticle[0].author),
        (this.content = data.data.TtArticle[0].content),
        (this.isChoiceness = data.data.TtArticle[0].isChoiceness),
        (this.isRecommend = data.data.TtArticle[0].isRecommend),
        (this.reference = data.data.TtArticle[0].reference),
        (this.intro = data.data.TtArticle[0].intro),
        (this.createTime = showTimeFormat(data.data.TtArticle[0].createTime));
      // return that.tableData;
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.new {
  margin-top: 20px;
}
.text {
  width: 500px;
}
#selImg {
  /* display: none; */
  width: 100px;
  position: relative;
  left: -100px;
  opacity: 0;
}
.font-siz {
  width: 500px;
  height: 200px;
  font-size: 13px;
}
</style>
